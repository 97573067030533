// Global packages and components
import { clsx } from 'clsx'
import { Link } from '@remix-run/react'
import {
  ChevronLeftIcon,
  PencilIcon,
  TrashIcon,
  ArrowLeftIcon,
} from '@heroicons/react/24/outline'

// Types
import type { ReactNode } from 'react'

interface Props {
  label?: string
  children?: ReactNode
  to?: string
  size?: 'xs' | 'sm' | 'md' | 'lg'
  icon?: 'chevronLeftIcon' | 'pencilIcon' | 'trashIcon' | 'arrowLeftIcon'
  error?: boolean
  disabled?: boolean
  onClick?: () => void
}

// Main export
const InlineLink = ({
  to,
  label,
  children,
  size = 'md',
  icon,
  error = false,
  disabled = false,
  onClick,
}: Props = {}) => {
  const regex = new RegExp('^(http|https|mailto|tel)://', 'i')
  const classes = clsx('hss-link font-bold underline', {
    'text-xs': size == 'xs',
    'text-sm': size == 'sm',
    'text-base': size == 'md',
    'text-lg': size == 'lg',
    'text-red-500': error,
    'text-teal-green': !disabled && !error,
    'pointer-events-none cursor-default': disabled,
    'text-gray-400': disabled && !error,
  })

  const iconSize = clsx({
    'size-2': size == 'xs',
    'size-2.5': size == 'sm',
    'size-3': size == 'md',
    'size-3.5': size == 'lg',
  })

  const innerLabel = icon ? (
    <div className="flex items-center gap-1">
      {icon == 'chevronLeftIcon' ? (
        <ChevronLeftIcon className={iconSize} />
      ) : icon == 'pencilIcon' ? (
        <PencilIcon className="size-4" />
      ) : icon == 'trashIcon' ? (
        <TrashIcon className="size-4" />
      ) : icon == 'arrowLeftIcon' ? (
        <ArrowLeftIcon className="size-4" />
      ) : (
        ''
      )}
      {label}
    </div>
  ) : (
    label
  )

  return onClick ? (
    <button
      disabled={disabled}
      aria-disabled={disabled}
      onClick={onClick}
      className={classes}
    >
      {children ?? innerLabel}
    </button>
  ) : to && regex.test(to) ? (
    <a
      aria-disabled={disabled}
      href={to}
      target="_blank"
      rel="noreferrer"
      className={classes}
    >
      {children ?? innerLabel}
    </a>
  ) : to ? (
    <Link
      aria-disabled={disabled}
      to={to}
      className={classes}
    >
      {children ?? innerLabel}
    </Link>
  ) : null
}

export default InlineLink
